import React from 'react';
import Layout from '../../components/layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="text-center mx-auto my-5">
        <div className="badge-title">Terms of Service</div>
        <h2 className="page-title d-block">Terms of Service</h2>
        <p>Last modified May 30, 2022</p>
      </div>

      <div className="section-main">
        <p>
          Notice of Arbitration Agreement: These Terms of Service contain an Arbitration Agreement that may impact your
          rights to use the courts to resolve disputes. Please carefully review the terms of the Arbitration Agreement
          below. If you do not wish to be bound by the Arbitration Agreement, do not use the Service as defined herein.
        </p>
        <p>
          Notice of Class Action/Class Arbitration Waiver: These Terms of Service include a waiver of your right to
          proceed with class actions or class arbitrations. If you do not wish to waive rights to class-based relief, do
          not use the Service.
        </p>
        <p>
          This HEXACT Terms of Service Agreement (“Terms”) applies to (1) your use of HEXACT’ services, software, and
          websites, including the websites located at hexometer.com, hexowatch.com, hexomatic.com and any other website
          owned or controlled by HEXACT (collectively, the “Service”); and (2) all material and data that you download,
          receive, or otherwise obtain through the use of the Service (“HEXACT Data”), including data provided to HEXACT
          by third parties (“Partner Data”).
        </p>
        <p>
          By accessing or using the Service you agree to these Terms and any other referenced terms, conditions, and
          policies. You further acknowledge that by using the Service, you necessarily access computers, servers,
          networks, and other hardware owned and operated by HEXACT to provide the Service (collectively, the “System”).
          If you are accessing or using the Service on behalf of a business, corporation, partnership, non-profit, or
          other organization (an “Enterprise Customer”) then you are agreeing to these Terms on behalf of the Enterprise
          Customer. If you are not authorized to bind the Enterprise Customer to these Terms, then do not access or use
          the Service on its behalf.
        </p>
        <p>
          Your authorization to access the System and use the Service is conditioned on your acceptance of and
          compliance with these Terms. If you cannot or do not accept these Terms, you are not authorized to access the
          System or use the Service and any access of the System or use of the Service by You is unauthorized.
        </p>
        <p>
          HEXACT reserves the right to change, modify, add, or remove portions of these Terms at any time. HEXACT will
          alert you of any changes by indicating at the top of these Terms the date they were last revised. It is your
          responsibility to check these Terms periodically for changes. Your use of the Service following the posting of
          revised Terms means you accept and agree to the changes.
        </p>
        <p>IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE.</p>
        <p>I. The Service</p>
        <p>
          (a) Requirements for Use of the Service. In order to use the Service, you must be at least 18 years old and
          have legal capacity to enter binding contracts. Additionally, you must not be barred from accessing or using
          the Service under United States law or the laws applicable where you reside or from where you access or use
          the Service.
        </p>
        <p>
          (b) HEXACT Limited License to You. Subject to these Terms, HEXACT grants you a limited, revocable,
          non-assignable, non-transferable, non-sublicensable, and non-exclusive right and license to access and use the
          Service; provided that you do not (and do not allow any third party to) sell, assign, sublicense, grant a
          security interest in or otherwise transfer any right in the Service or HEXACT Data or attempt to do so.
        </p>
        <p>
          (c) Membership Account Registration. Certain features or services offered on or through the Service may
          require you to open a membership account. In order to open an account, you must provide HEXACT with certain
          information (“Registration Data”) requested on the registration form; inquiries marked “required” must be
          answered, and any other request for information may be left blank. You agree that the Registration Data you
          provide: (1) is true, accurate, current, and complete, and (2) will be maintained and updated by you to keep
          it true, accurate, current and complete. HEXACT reserves the right to suspend or terminate your use of the
          Service and refuse to provide you with any and all current or future use of the Service if HEXACT, in its sole
          discretion, determines that any of your Registration Data is untrue, inaccurate, not current, or incomplete.
        </p>
        <p>
          (d) Account Security. During the registration process you will provide an email address, which will function
          as your user name, and select a password, both of which you may be able to modify later subject to certain
          system and technical restrictions (“Account Credentials”). You are responsible for maintaining the
          confidentiality of your Account Credentials and are fully responsible for all activity that occurs under your
          Account Credentials or that uses your account whether or not authorized by you. Your account will be charged
          for all activity authenticated with your Account Credentials even if you later determine the use was
          fraudulent or unauthorized by you. You will immediately notify HEXACT of any unauthorized use of your Account
          Credentials or any other breach of security. HEXACT cannot, and will not, be liable for any loss or damage
          arising from your failure to comply with this requirement.
        </p>
        <p>
          (e) Single User Accounts. With the exception of certain paid membership types, HEXACT accounts may only be
          accessed and used by the registered user. Sharing, selling, or transferring your Account Credentials with
          another user is prohibited and a basis for immediate account termination or suspension.
        </p>
        <p>
          (f) Paid Memberships. Certain features or services offered on or through the Service may require a paid
          membership. Available membership types and associated fees, terms, and conditions will be posted on a website
          associated with the Service. The term of a paid membership starts when you submit the registration form and
          continues for the duration you selected during account registration (a “Membership Term”)—e.g. one month or
          one year. You agree that you will pay for the membership and any additional services or products you purchase
          through the Service, and that HEXACT may charge your credit card or payment account for any membership
          upgrades purchased and for any additional amounts (including any taxes, as applicable) that may be accrued by
          or in connection with your account.
        </p>
        <p>
          (g) Automatic Renewal; Subsequent Terms. The Membership Term will automatically renew, unless terminated by
          you prior to the end of the Membership Term, for an additional period of the same duration, or at your
          election, another period then offered by HEXACT (an “Additional Term”). Similarly, any Additional Term will
          automatically renew unless terminated by you prior to the end of an Additional Term for another Additional
          Term, or at your election, another period then offered by HEXACT. If you do not elect an Additional Term of a
          different period than your current term, then you agree to pay for an Additional Term of the same duration as
          your current term based on the pricing in effect at the time of renewal. If you elect an Additional Term for a
          different period, you agree to pay the fees disclosed by HEXACT in connection with that different period. You
          authorize HEXACT to charge the credit card on file for an amount equal to the fees for the Additional Term
          unless you have canceled prior to the end of the current term.
        </p>
        <p>
          (h) Free Trial. If you register for a Free Trial, you will be entitled to access Services (as defined in the
          Terms of Service) for a period of seven (7) days, from the moment that you activate such trial period by
          submitting your payment details. By submitting your payment details, you accept the Free Trial offer and
          consent to us using your payment details in accordance with our Privacy Policy. This is a recurring
          subscription and you will be charged automatically on a recurring basis unless you terminate your Subscription
          upon the lapse of the Free Trial Period. Unless you purchase a subscription to the applicable Subscription
          Service before the end of the free trial, all of your data in the Subscription Service may be permanently
          deleted at the end of the trial, and we will not recover it. You may only use this Free Trial Offer once.
          Hexact Inc. reserves the right, in its absolute discretion, to withdraw or to modify this Free Trial Offer at
          any time without prior notice and with no liability.
        </p>
        <p>
          (i) Membership Changes. You may change your membership level by upgrading from one membership type to another.
          For example, you may upgrade from one type of paid membership to another type of paid membership. An upgrade
          will take effect immediately, and you will be charged a prorated fee based on the remaining length of the
          current Membership Term, if applicable. You may downgrade your membership level by renewing at a different
          membership type or electing to not renew prior to the end of your current Membership Term. A downgrade will
          take effect at the end of the current Membership Term.
        </p>
        <p>
          (j) Fees. All fees and charges paid by you in relation to the Service are nonrefundable, except as required by
          law or as otherwise stated herein. HEXACT accepts American Express, Visa, MasterCard and requires, for some
          membership types, that You have one such payment method on file with HEXACT before you may use the Service.
          All fees will be billed to the credit card or payment account you designate during the registration process.
          If HEXACT is unable to successfully charge your credit card or payment account for fees due or you are
          otherwise in breach or default under these Terms, HEXACT reserves the right to immediately terminate or
          suspend your access to the Service, including your access to any reports or information available through your
          account. In the event you change your credit card or payment information or cancel the credit card or payment
          account on file with HEXACT, you will (1) promptly advise HEXACT of the change or cancellation and provide
          updated credit card or payment account information that can be charged by HEXACT, and (2) pay any amount due
          to HEXACT for the Service.
        </p>
        <p>
          (k) Account Disputes and Unacceptability of Chargebacks. If you have a question about charges made to your
          account, please contact HEXACT immediately. You may request that HEXACT refund charges to your charge, credit
          or debit cards. HEXACT will review such request and respond within 5 business days. However, you acknowledge
          and agree that chargebacks requested through your issuing bank are an unacceptable charge dispute resolution
          method. If you dispute the credit card charge that HEXACT charges for its services, HEXACT reserves the right
          to suspend your account as soon as HEXACT is informed of your chargeback request. You agree to immediately pay
          to HEXACT any chargebacks of amounts originally charged to your charge, credit or debit cards, as well as for
          fees incurred by HEXACT for such chargebacks. Your responsibility for chargebacks and related fees will
          continue even if you have received the funds, and/or closed your account. Any chargeback fees, past due fees,
          and costs will be sent to collections. If HEXACT’ collection efforts fail, unpaid debts may be reported to the
          State of Delaware, all available credit reporting agencies and Internet fraud databases.
        </p>
        <p>
          (l) Termination or Modification of Service. HEXACT reserves the right, without liability to you or any other
          third party, to modify or discontinue, temporarily or permanently, in whole or in part, the Service without
          notice. For example, HEXACT may change at any time the types of memberships available and the specific terms
          applicable to each membership. If your existing membership type is no longer available at the end of your
          current term and you have not cancelled your membership, HEXACT may, in its reasonable discretion, cause a
          Subsequent Term to proceed under the terms of your current membership type or under the terms of another
          available membership type that is reasonably comparable to your current membership. You agree that HEXACT
          shall not be liable to you or any third party for any modification or termination of the Service.
        </p>
        <p>
          (m) Access to the Service. HEXACT reserves the right to change the requirements for accessing the Service or
          System at any time and for any reason in its sole discretion, including changes to membership fees or other
          fees. HEXACT reserves the right to alter, suspend, or discontinue the Service, or any portion thereof, at any
          time and for any reason, without prior notice to you. The Service may also become unavailable due to
          maintenance or malfunction of computer equipment or other reasons. HEXACT may at any time terminate your
          membership or block your access to the Service if: (1) You have breached any provision of these Terms (or have
          acted in a manner that indicates you do not intend to, or are unable to, comply with these Terms); (2) HEXACT
          is required to do so by applicable law; (3) the provision of the Service to you by HEXACT is, in HEXACT’
          discretion, no longer commercially viable; or (4) HEXACT has elected to discontinue the Service, or any
          portion thereof, for any reason. HEXACT shall not be liable to you or any third party for any termination or
          cancellation of your access to, or use of, the Service.
        </p>
        <p>
          (n) Guest Access. HEXACT permits You to access the Service without a Membership Account for limited purposes.
          You agree that HEXACT may change the limitations on Guest Access at any time for any reason, including
          eliminating Guess Access entirely. You further agree that HEXACT may require you to complete a user
          verification form (e.g. a Captcha) to prevent abuse of the Service.
        </p>
        <p>II. Restrictions on Use</p>
        <p>
          (a) Permitted Use. You agree to use the Service only for purposes permitted by these Terms and any applicable
          law or regulation.
        </p>
        <p>
          (b) Compliance With Laws, Regulations and Requirements. You will not use the Service for illegal purposes
          (allow, enable, or otherwise support the transmission by e-mail, telephone, postal mail, facsimile or other
          means of mass unsolicited, commercial advertising or solicitations) but will abide by and comply with all
          applicable local, state, national, and international laws and regulations in your use of the Service
          (including laws regarding the transmission of technical data exported from the United States). You will not
          use the Service, Partner Data, or HEXACT Data to access without authorization any protected computer as such
          terms are defined by the Computer Fraud and Abuse Act, 18 U.S.C. § 1030.
        </p>
        <p>
          (c) Non-Interference. You will not interfere with or disrupt (1) the use and enjoyment of the Service by other
          users; or (2) the Service or System, including servers or networks connected to the Service (including,
          without limitation, any attempt to gain unauthorized access to other computer systems or networks connected to
          the Service).
        </p>
        <p>
          (d) Excessive Use. You acknowledge that excessive use of network bandwidth associated with the Service risks
          degrading the quality of the System, which could negatively impact the use and enjoyment by others of the
          Service or impose undue costs on HEXACT. HEXACT may terminate or place reasonable restrictions on your use of
          the Service if it determines, in its sole discretion, that your use of the Service is excessive or puts
          unreasonable stress on the System.
        </p>
        <p>
          (e) No Unauthorized Automated Access. You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or
          other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to
          access, acquire, copy or monitor any portion of the Service or any Partner Data or HEXACT Data, or to in any
          way reproduce or circumvent the navigational structure or presentation of the Service, to obtain or attempt to
          obtain any materials, documents or information through any means not purposely made available through the
          Service, such as the HEXACT API, and subject to applicable additional terms and conditions.
        </p>
        <p>
          (f) No Resale. You may not resell, rent, trade, or copy the Service, Partner Data or HEXACT Data, the use of
          or access to the Service, Partner Data, or HEXACT Data, or any portion thereof, except as otherwise agreed in
          writing between you and HEXACT. If you are interested in reselling the Service, please contact HEXACT at
          admin@hexact.io for more information.
        </p>
        <p>
          (g) Information and Access Not Guaranteed. You acknowledge that the Service, and any information available
          through the Service, including Partner Data and HEXACT Data, may not always be available, complete, or
          accurate, and that the Service may not always satisfy your anticipated or required level of performance.
          Further, you understand and acknowledge that despite the exercise of commercially reasonable efforts on the
          part of HEXACT, some of the information available through the Service, including Partner Data or HEXACT Data,
          may be inaccurate, incomplete, or become unavailable, or may be different in scope or content from information
          available from other HEXACT services. Under no circumstances shall HEXACT be liable for Your reliance on the
          accuracy of any Partner Data accessed via the Service.
        </p>
        <p>
          (h) No Reverse Engineering. You may not (and will not allow any third party to) reverse engineer, decompile,
          disassemble, or otherwise attempt to discover any source code, object code or underlying structure, ideas or
          algorithms of the Service or any software, documentation or data related to or provided with the Service.
        </p>
        <p>
          (i) No Derivative Works. Except as expressly authorized by HEXACT, you agree not to copy, modify, rent, lease,
          loan, sell, distribute, or create derivative works based, in whole or in part, on the Service, Partner Data,
          or HEXACT Data. No portion of the Service may be reproduced in any form or by any means, except as expressly
          permitted in these Terms.
        </p>
        <p>
          (j) No Modification or Circumvention. You agree not to modify the Service or System in any manner or form, or
          to use modified versions of the Service, including (without limitation) for the purpose of obtaining
          unauthorized access to the Service, System, Partner Data, or HEXACT Data. You agree not to access the Service
          or System by any means other than through an interface provided by HEXACT for use in accessing the Service.
          You may not knowingly use or access the Service to create, improve, verify, or support (directly or
          indirectly) a product or service competitive to the Service, including, without limitation, any other domain
          registration information service.
        </p>
        <p>III. HEXACT’ Proprietary Rights</p>
        <p>
          Except for the licenses expressly granted herein, HEXACT retains all rights and interest in: (1) the Service,
          System, and all other materials, tools, templates, matrices, documentation, technology, or APIs developed by
          or on behalf of HEXACT or provided by HEXACT, under these Terms, the API Terms, an Enterprise Quote, or
          otherwise; (2) all other proprietary information, products, and services of HEXACT; (3) all customizations,
          modifications, enhancements, derivative works, configurations, translations, upgrades, and interfaces in and
          to each of the items in (1) and (2) above; (4) the intellectual property rights in and to each of the items in
          (1) through (3) above; and (5) the ideas, concepts, techniques, inventions, processes, software, or works of
          authorship developed, embodied in, or practiced in connection with the Service. HEXACT does not claim
          proprietary rights to any Partner Data except as otherwise agreed between HEXACT and the owner of the Partner
          Data.
        </p>
        <p>IV . Third-Party Rights, Services, and Data</p>
        <p>
          (a) Third Party Services and Data. HEXACT is not responsible for, and does not endorse or guarantee the
          availability of, third-party websites or other resources, which may be accessed in connection with or by using
          the Service, specifically including any Partner Data accessed as part of the Service. Because HEXACT may have
          no control over such third-party websites or resources, you acknowledge and agree that HEXACT will not be
          responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
          connection with your use of or reliance on any such content, advertising, goods or services, or other
          materials on or available from such third-party websites or resources.
        </p>
        <p>
          (b) Third Party Rights. Content or information available through the Service, including Partner Data and
          HEXACT Data, may be protected by third parties’ copyrights, trademarks, service marks, patents, or other
          proprietary rights and laws, all of which are the property of their respective owners.
        </p>
        <p>
          (c) Display of Third Party Advertising. HEXACT may, in its sole discretion, advertise on behalf of third
          parties, including third parties who sell or make available for purchase domain names. HEXACT expressly
          disclaims any liability related to the availability of and pricing for any domains displayed on the Service.
        </p>
        <p>V. Your Privacy</p>
        <p>
          HEXACT believes strongly in protecting user privacy and providing you with notice of HEXACT’ use of data
          collected in connection with use and access to the Service, including personally identifying information.
          Please refer to the full HEXACT Privacy Policy at https://hexact.io/privacy-policy/ for more information
          regarding how HEXACT uses and collects information.
        </p>
        <p>VI. HEXACT’ License to Your Submissions</p>
        <p>
          You give HEXACT a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to reproduce,
          adapt, modify, translate, publish, publicly perform, publicly display, and distribute any queries submitted to
          the Service and any resulting Partner Data or HEXACT Data.
        </p>
        <p>VII. Indemnity</p>
        <p>
          You will release, indemnify, defend, and hold harmless HEXACT and any of its parents, affiliates, officers,
          directors, employees, agents, partners, licensors, and assigns from all liabilities, claims, damages, costs,
          and expenses, including reasonable attorneys’ fees and expenses, of third parties relating to or arising out
          of: (1) these Terms or the breach of your warranties, representations and obligations under these Terms; (2)
          your use of the Service; (3) your use of HEXACT Data or Partner Data; (4) any use of your account or Account
          Credentials, whether or not such use was authorized by you; (5) any intellectual property or other proprietary
          right of any person or entity; (6) your violation of any of the provisions of these Terms; (7) any action
          taken by HEXACT as part of its investigation of a suspected violation of this Terms or as a result of HEXACT’
          determination that a violation of this Terms has occurred; (8) any information or data you supplied to HEXACT,
          including, without limitation, any incorrect information in your Registration Data; or (9) your violation of
          any rights of a third party.
        </p>
        <p>
          When HEXACT is threatened with suit or sued by a third party, HEXACT may seek written assurances from you
          concerning your promise to indemnify HEXACT; your failure to provide those assurances may be considered by
          HEXACT to be a material breach of these Terms. HEXACT will have the right to participate in any defense by you
          of a third-party claim related to your use of any of the Service, with counsel of HEXACT’ choice at its
          expense. HEXACT will reasonably cooperate in any defense by you of a third-party claim at your request and
          expense. You will have sole responsibility to defend HEXACT against any claim, but you must receive HEXACT’
          prior written consent regarding any related settlement.
        </p>
        <p>VIII. DISCLAIMER OF WARRANTIES</p>
        <p>
          YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
          .HEXACT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
          TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT WITH
          RESPECT TO THE SERVICE, ANY HEXACT DATA OR PARTNER DATA, AND ANY GOODS OR SERVICE OBTAINED ON OR THROUGH THE
          SERVICE.
        </p>
        <p>
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, HEXACT MAKES NO WARRANTY: (1) THAT THE SERVICE WILL MEET
          YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (2) AS TO THE RESULTS THAT MAY BE OBTAINED
          FROM THE USE OF THE SERVICE OR AS TO THE ACCURACY OR RELIABILITY OF ANY HEXACT DATA OR PARTNER DATA OR THAT
          DEFECTS IN THE SERVICE OR HEXACT DATA OR PARTNER DATA WILL BE CORRECTED; OR (3) REGARDING ANY HEXACT DATA OR
          PARTNER DATA (DOWNLOADING OR ACCESS IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE
          FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM DOWNLOADING OR ACCESSING HEXACT DATA
          OR PARTNER DATA).
        </p>
        <p>
          HEXACT DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICE WILL BE FREE FROM LOSS, CORRUPTION, ATTACK, VIRUSES,
          INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND HEXACT DISCLAIMS ANY LIABILITY RELATING THERETO.
        </p>
        <p>
          YOU FURTHER ACKNOWLEDGE THAT THE SERVICE IS NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR ENVIRONMENTS
          WHERE THE FAILURE OR TIME DELAYS OF, OR ERRORS OR INACCURACIES IN, THE CONTENT, DATA, OR INFORMATION PROVIDED
          BY THE SERVICE COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM HEXACT OR THROUGH OR FROM THE SERVICE
          WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT
          APPLY TO YOU.
        </p>
        <p>IX. LIMITATION OF LIABILITY</p>
        <p>
          HEXACT AND ITS PARENTS, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS WILL NOT
          BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT
          NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA, OR OTHER INTANGIBLE LOSSES, RESULTING
          FROM (1) THE USE OR THE INABILITY TO USE THE SERVICE; (2) THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;
          OR (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA THROUGH THE SERVICE, EVEN
        </p>
        <p>IF</p>
        <p>HEXACT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <p>
          HEXACT WILL NOT BE LIABLE FOR ANY DAMAGES ARISING FROM INTERRUPTION, SUSPENSION, OR TERMINATION OF THE
          SERVICE, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL, OR EXEMPLARY
          DAMAGES, WHETHER SUCH INTERRUPTION, SUSPENSION, OR TERMINATION WAS JUSTIFIED OR NOT, NEGLIGENT OR INTENTIONAL,
          INADVERTENT, OR ADVERTENT.
        </p>
        <p>
          HEXACT ‘ ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN EQUITY, OR OTHERWISE, WITH RESPECT TO ANY
          SERVICES PROVIDED UNDER THESE TERMS AND/OR FOR ANY BREACH OF THESE TERMS IS SOLELY LIMITED TO THE AMOUNT YOU
          PAID TO HEXACT FOR THE SERVICE.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <p>X. Notices and Announcements</p>
        <p>
          You authorize HEXACT to notify you of information that HEXACT deems may be of potential interest to you.
          Notices and announcements may include commercial emails and other notices describing changes, upgrades, new
          products and services, or other information. All notices must be sent either in writing (including email, but
          only to the extent expressly provided herein). All written notices to HEXACT shall be delivered to HEXACT LLC,
          Attention: Legal, HEXACT, Inc. 1250 E. Hallandale Beach Blvd., Unit 808, Hallandale Beach, Florida 33009. All notices to you may be
          delivered to your mailing address or email address as provided in your account information (as provided and
          maintained by you pursuant to these Terms). HEXACT may also provide notices of changes to these Terms or any
          other matter by displaying notices to you generally on the HEXACT website.
        </p>
        <p>XI. Termination and Survival</p>
        <p>
          (a) By HEXACT. HEXACT may terminate, limit, or suspend your account or access to the Service at any time,
          immediately and without notice (1) in the event you breach these Terms, including without limitation by
          failing to make any payment when due; (2) fail to respond within 10 days to an inquiry from HEXACT concerning
          the accuracy or completeness of your Registration Data; (3) if HEXACT determines in its sole discretion that
          you have violated these Terms; or (4) for any other reason, without cause and without liability.
        </p>
        <p>
          (b) By You. You may terminate your membership or stop accessing or using the Service at any time. You may
          terminate using account settings on the HEXACT website.
        </p>
        <p>
          (c) Insolvency of Enterprise Customer. HEXACT may terminate, limit, or suspend an Enterprise Customer’s
          account or its access to the Service at any time, immediately and without notice if Enterprise Customer (1)
          admits in writing its inability to pay its debts generally as they become due;
        </p>
        <p>
          (2) makes a general assignment for the benefit of its creditors; (3) institutes proceedings, or has
          proceedings instituted against it seeking relief or reorganization under any laws relating to bankruptcy or
          insolvency; (4) has a court of competent jurisdiction appoint a receiver, liquidator, or trustee over all or
          substantially all of Enterprise Customer’s property or provide for the liquidation of Enterprise Customer’s
          property or business affairs; or (5) fails to make timely payments for Services as provided for in Section
          III(c) of this Agreement.
        </p>
        <p>
          (d) Effect of Termination. You will not receive any refund for payments already made by you as of the date of
          termination. If termination is due to your breach of these Terms, you will bear all costs of such termination,
          including any reasonable costs HEXACT incurs in closing your account or disabling or preventing your further
          access. You will pay any and all costs incurred by HEXACT in enforcing your compliance with these Terms. Upon
          termination, you shall destroy all copies of HEXACT Data and/or Partner Data. Notwithstanding termination of
          these Terms by you or by HEXACT, the provisions in these Terms shall remain in full force and effect. Upon
          termination, you will no longer have access to any data or information you had previously created, maintained,
          managed, or stored in or through the Service and HEXACT is under no obligation to maintain, provide access to,
          or preserve any such data or information. HEXACT may, in its discretion, destroy or retain your account and
          all associated data and information.
        </p>
        <p>
          (e) Survival. The following terms survive termination of these Terms or the Services: Sections I(d), I(f),
          I(g), I(h), I(j), I(k), I(l), II(f), III(c), IV, V, VI, VII, VIII, X, XI, XII, as well as any other provision
          that expressly states that it survives termination.
        </p>
        <p>XII. General</p>
        <p>
          (a) Entire Agreement. These Terms constitute the entire agreement between HEXACT and you with respect to the
          Service. No prior or contemporaneous written, oral, or electronic representation form a part of these Terms,
          and these Terms supersede all prior and contemporaneous electronic, oral, and written agreements,
          negotiations, and representations between HEXACT and you relating to the subject matter of this Terms;
          provided that, where applicable, these Terms also include (1) the API Terms; (2) any Enterprise Quote; or (3)
          any other writing signed by both you and HEXACT.
        </p>
        <p>
          (b) Amendments in Writing. No amendment, modification, or supplement to the Terms will be effective unless it
          is in writing and signed by authorized representatives of both HEXACT and you.
        </p>
        <p>
          (c) No Assignment. Your obligations under this Agreement are personal. Without HEXACT’ express written
          consent, you cannot assign any rights or delegate any duties under these Terms. Any purported assignment or
          delegation in violation of this section is void.
        </p>
        <p>
          (d) Enterprise Customers–Successor and Related Entities. These Terms are binding on any corporation,
          partnership, limited liability company, joint venture or other legal entity that an Enterprise Customer owns
          in whole or part, or which owns any interest in Enterprise Customer, or which is commonly owned or controlled
          in whole or part with Enterprise Customer, including any successor entity resulting from a merger with or
          acquisition of Enterprise Customer.
        </p>
        <p>
          (e) No Third-Party Beneficiaries. These Terms are not intended to, and will not be construed to, provide any
          rights, remedies, or benefits to or for any person or entity not a party to this Terms.
        </p>
        <p>
          (f) Effect of Waiver. The failure of either HEXACT or you at any time to enforce any right or remedy under
          these Terms with respect to any breach or failure by the other party will not be construed to be a waiver of
          such right or remedy with respect to any other breach or failure by the other party.
        </p>
        <p>
          (g) Arbitration. Any controversy or claim arising out of or relating to these Terms, or the breach thereof, or
          your access to or use of the Service shall be resolved exclusively by arbitration administered by the American
          Arbitration Association (“AAA”) and conducted before a single arbitrator pursuant to the applicable Rules and
          Procedures established by the AAA. You further agree that (1) the arbitration will be held in Seattle, Delaware; 
          (2) the arbitrator shall apply the laws of the State of Delaware, without regard to its conflict of
          law principles to the contrary; (3) you waive any right to proceed in arbitration on a class or representative
          basis; (4) arbitration can resolve only claims between you and HEXACT; (5) the arbitrator may not consolidate
          or join the claims of other persons or parties who may be similarly situated; and (6) all parts of this clause
          are severable, meaning that if any part is deemed unenforceable, the remainder of the clause will remain in
          effect and construed in accordance with its terms.
        </p>
        <p>
          (h) Governing Law. These Terms will be governed by the laws of the State of Delaware without regard to its
          conflict of law principles to the contrary, except that the Arbitration provision above shall be governed by
          the Federal Arbitration Act.
        </p>
        <p>
          (i) Limitations. You agree that regardless of any statute or law to the contrary, any claim or cause of action
          arising out of or related to these Terms or your access to or use of the Service must be filed within 1 year
          after such claim or cause of action arose or be forever barred.
        </p>
        <p>
          (j) Interpretation and Severability. If any provision of these Terms is held to be invalid, void, or
          unenforceable, the remaining provisions will remain in full force and effect. The language of these Terms will
          be construed as a whole according to its fair meaning, and not strictly for or against any party. As used in
          these Terms: (1) words and terms capitalized for purposes of definition will carry the same meaning
          throughout; (2) the singular will also indicate the plural and vice versa; and (3) a word modified by an
          article (such as “the” or “an” or “a”) does not necessarily mean that just one of the subject exists.
        </p>
        <p className="mb-0">
          (k) Force Majeure. Neither party will be deemed in default hereunder, nor will it hold the other party
          responsible for, any cessation, interruption or delay in the performance of its obligations hereunder due to
          earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike,
          lockout, or boycott, provided that the party relying upon this section (1) will give the other party written
          notice thereof promptly and, in any event, within 10 days of discovery thereof and (2) will take all steps
          reasonably necessary under the circumstances to mitigate the effects of the force majeure event upon which
          such notice is based; provided further, that in the event a force majeure event described in this section
          extends for a period in excess of 30 days in the aggregate, HEXACT may immediately terminate these Terms
          and/or your access to or use of the Service.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
